<template>
  <div class="main">
    <div style="max-width:1560px;margin:0 auto">
      <el-carousel height="675px" width="1200px">
        <el-carousel-item v-for="item in banners" :key="item">
          <img :src="item" alt="" class="carouselItem">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--  -->
    <div class="productsType container">
      <router-link class="productsTypeImg" :to="{name:'products',params:{index:3,type:1}}">
        <img src="http://www.szxcd.com.cn/content/img/type1.png" alt="">
        <div class="productsTypeImg1">
          <img src="http://www.szxcd.com.cn/content/img/see.png" alt="">
        </div>
      </router-link>
      <router-link class="productsTypeImg" :to="{name:'products',params:{index:3,type:2}}">
        <img src="http://www.szxcd.com.cn/content/img/type2.png" alt="">
        <div class="productsTypeImg1">
          <img src="http://www.szxcd.com.cn/content/img/see.png" alt="">
        </div>
      </router-link>
    </div>
    <!-- 产品展示 -->
    <div class="productsShow container">
      <p class="title">产品展示</p>
      <!-- <p class="subTitle">8条组装线/200台月产能/洁净环境应用</p> -->
      <div class="productsShow1">
        <router-link :to="{path:'/productsinfo',query:{parentname:item.parentname,name:item.name,id:item.id}}" tag="div" v-for="item in products" :key="item.img">
          <img :src="item.img" alt="">
          <p>{{item.name}}</p>
        </router-link>
      </div>
      <router-link class="productsShow2" style="background-color: #154ec3;" :to="{name:'about',params:{index:1}}" tag="div">关于我们</router-link>
    </div>
    <!-- 客户案例 -->
    <!-- <div class="container cases">
      <p class="title">客户案例</p>
      <p class="subTitle">5年研发制造经验</p>
      <div class="casesContainer">
        <router-link :to="{path:'/caseinfo',query:{parentname:'新闻中心',name:'案例1',index:null}}" tag="div" v-for="item in 6" :key="item">
          <img src="http://www.szxcd.com.cn/content/img/banner.jpeg" alt="">
          <p class="successCase">成功案例</p>
          <p class="successCaseName">啊实打实大时代</p>
        </router-link>
        
      </div>
      <router-link class="productsShow2" :to="{name:'cases',params:{index:-1}}" tag="div">案例解析</router-link>
    </div> -->
    <!-- 关于 -->
    <div class="container about">
      <!-- <p class="title">关于长臂猿</p>
      <p class="subTitle">专业制造伸缩货叉--中国自己的品牌</p>
      <img src="http://www.szxcd.com.cn/content/img/about.jpg" alt="">
      <router-link class="productsShow2" :to="{name:'factoryView',params:{index:2}}" tag="div">工厂实景</router-link> -->
      <div class="aboutimg">
        <!-- <img src="http://www.szxcd.com.cn/content/img/indexabout.jpg" alt=""> -->
        <div style="width: 49%;background-image: linear-gradient(to bottom, #a9d0ef, #94c4ec);">
          <div style="font-size: 13px;line-height: 20px;padding:0 5%;">
            <div style="width: 100%;text-align: center;margin-top: 1rem;margin-bottom: 2rem;font-size: 18px;font-weight: 600;">公&nbsp;司&nbsp;简&nbsp;介</div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳市欣畅达科技有限公司于2021年4月成立于深圳市宝安区石岩街道创维创新谷(研发中心)，目前具备制造基地面积7000平方米，座落于惠州市博罗县龙溪镇结窝村。公司核心成员是新能源设备领域及军工领域的先进专家。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司目前在职员工约60人，其中研发人员占比40%。制造中心配备了机加设备及钣金加工设备设备共15台，轻型重型行吊起重机共6台，并拥有自建喷涂产线。具备自研，自产的全流程能力。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司技术团队具有行业首创的创新精神，能够不断地推陈出新，开创出业内领先的解决方案。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的技术功底扎实，团队成员都具有广泛而深入的技术知识和实践经验，能够迅速响应客户的需求并提供高质量的解决方案。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的团队还具备丰富的项目经验，我们已经成功完成了多个复杂的自动化项目，积累了丰富的经验和知识，这使我们能够迅速识别客户需求，并提供最适合的解决方案，为客户创造更大的价值。</div>
        </div>
        <img src="http://www.szxcd.com.cn/content/img/indexabout2.png" alt="">
      </div>
      <video controls src="http://www.szxcd.com.cn/content/mj.mp4" poster="http://www.szxcd.com.cn/content/img/sp.png" style="width:100%;margin-top:20px"></video>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  data () {
    return {
      banners:[
        'http://www.szxcd.com.cn/content/img/indexbanner.png',
        'http://www.szxcd.com.cn/content/img/banner1.jpg',
        'http://www.szxcd.com.cn/content/img/banner2.jpg',
        'http://www.szxcd.com.cn/content/img/banner3.jpg',
        'http://www.szxcd.com.cn/content/img/banner4.jpg',
      ],
      products:[
        {
          img:'http://www.szxcd.com.cn/content/img/p1.jpg',
          name:'XCD-GS',
          parentname:'堆垛机系列',
          type:1,
          id:1
        },
        {
          img:'http://www.szxcd.com.cn/content/img/p2.jpg',
          name:'XCD-GS',
          parentname:'堆垛机系列',
          type:1,
          id:2
        },
        {
          img:'http://www.szxcd.com.cn/content/img/h3.jpg',
          name:'LD-D-1Z',
          parentname:'货叉系列',
          type:2,
          id:7
        },
        {
          img:'http://www.szxcd.com.cn/content/img/h6.jpg',
          name:'LD-D-2Z',
          parentname:'货叉系列',
          type:2,
          id:10
        }
      ]  
    }
  },
  created () {
    window.scrollTo(0,0)
  },
}
</script>

<style scoped>
.aboutimg{
  display: flex;
  justify-content: space-between;
}
.aboutimg img{
  width: 49%;
}
.about{
  margin-top: 30px;
  margin-bottom: 50px;
}
.successCaseName{
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 21px;
  color: #444;
}
.successCase{
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 18px;
  color: #333;
}
.cases img{
  width: 300px;
  height: 200px;
  object-fit: cover;
}
.casesContainer{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.casesContainer>div:hover .successCaseName{
  color: #e8bb49;
  transition: all .5s;

}
.casesContainer>div{
  font-size: 0;
  transition: all .5s;
  cursor: pointer;
}
.cases{
  background-color: #f3f3f3;
  height: 886px;
  margin-top: 65px;
  padding: 40px 70px 0;
}
.productsShow2{
  width: 120px;
  height: 45px;
  line-height: 45px;
  color: #fff;
  background-color: #94c4ec;
  margin:50px auto 0;
  transition: all .5s;
  text-align: center;
  cursor: pointer;
}
.productsShow2:hover{
  color: #666;
  background-color: #f3f3f3;
}
.productsShow1 img{
  width: 277px;
  height: 219px;
}
.productsShow1{
  font-size: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.productsShow1 p{
  line-height: 55px;
  font-size: 16px;
}
.productsShow1>div:hover{
  color: #e8bb49;
}
.productsShow1>div{
  padding: 3px;
  width: 285px;
  height: 282px;
  border: 1px solid #eeedeb;
  cursor: pointer;
  transition: all .5s;
}
.title{
  color: #080804;
  font-size: 30px;
  text-align: center;
}
.subTitle{
  color: #888;
  font-size: 18px;
  text-align: center;
}
.productsShow p{
  text-align: center;
}
.productsShow{
  margin-top: 60px;
}
.productsTypeImg1 img{
  width: 42px !important;
  height: 42px !important;
}
.productsTypeImg1{
  width: 595px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all .5s;
}
.productsType{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.productsTypeImg:hover .productsTypeImg1{
  opacity: 1;
}
.productsTypeImg{
  display: block;
  position: relative;
  font-size: 0;
}
.productsType img{
  width: 595px;
  height: auto;
  object-fit: cover;
}
.carouselItem{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

</style>
