<template>
  <div class="main">
    <div style="max-width:1560px;margin:0 auto">
      <el-carousel height="675px" width="1200px">
        <el-carousel-item v-for="item in banners" :key="item">
          <img :src="item" alt="" class="carouselItem">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div class="aboutTop">
      <div class="container" style="width:900px">
        <p class="title">太仓介绍</p>
        <p class="subTitle">Taicang introduction</p>
        <p class="abouttext">
          太仓市，是一座江南名城，有着4500多设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <p class="abouttext">
          太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <p class="abouttext">
          太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
      </div>
    </div>
    <div class="aboutBottom">
      <div class="container"  style="width:900px">
        <p class="title">公司介绍</p>
        <p class="subTitle">Company introduction</p>
        <img class="aboutimg" src="http://www.szxcd.com.cn/content/img/aboutimg.jpeg" alt="">
        <p class="abouttext2">
          太仓市，是一座江南名城，有着4500多设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <p class="abouttext2">
          太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <img class="aboutimg" src="http://www.szxcd.com.cn/content/img/aboutimg.jpeg" alt="">
        <p class="abouttext2">
          太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <p class="abouttext2">
          太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <p class="abouttext2">4500多年的明历史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <img class="aboutimg" src="http://www.szxcd.com.cn/content/img/aboutimg.jpeg" alt="">
        <p class="abouttext2">
          太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
        <p class="abouttext2">
          太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故太仓市，是一座江南名城，有着4500多年的明历史，春秋时期，吴王在此设粮仓，故
        </p>
      </div>
      
    </div> -->
    <div class="container">
      <img src="http://www.szxcd.com.cn/content/img/about1.jpeg" alt="">
      <img src="http://www.szxcd.com.cn/content/img/about2.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: 'about',
  components: {
  },
  data () {
    return {
      banners:[
        'http://www.szxcd.com.cn/content/img/indexbanner.png',
        'http://www.szxcd.com.cn/content/img/banner1.jpg',
        'http://www.szxcd.com.cn/content/img/banner2.jpg',
        'http://www.szxcd.com.cn/content/img/banner3.jpg',
        'http://www.szxcd.com.cn/content/img/banner4.jpg',
      ],  
    }
  },
  created () {
    window.scrollTo(0,0)
  },
}
</script>

<style scoped>
.carouselItem{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.container{
  font-size: 0;
  margin-top: 20px;
}
.container img{
  width: 100%;
  max-width: 100%;
}
.aboutimg{
  max-width: 100%;
}
.abouttext2{
  color: #444;
}
.abouttext{
  font-size: 18px;
  color: #666;
  margin-top: 20px;
}
.aboutTop{
  padding-top: 55px;
}
.aboutBottom{
  padding-top: 35px;
  background-color: #fafaf9;
  padding-bottom: 50px;
}
.banner{
  width: 1560px;
  height: 630px;
  max-width: 100%;
  font-size: 0;
  display: block;
  margin: 0 auto;
}
.title{
  color: #080804;
  font-size: 40px;
  text-align: center;
}
.subTitle{
  color: #999;
  font-size: 14px;
  text-align: center;
}
</style>
